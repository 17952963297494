import breadcrumbComp from "../../common/breadcrumb-comp";
import AddFavourite from "../../utility/js/addFav.js";
import EncryptUtility from "../../utility/js/encrypt-utility.js";
import materialMovementService from "../js/material-movement-service";
import commonAPIService from "../../common/js/common-api-service";
export default {
  data() {
    return {
      actionList: [],
      mainPage: "",
      favouriteList: [],
      pageUrl: "",
      subPage: "",
      showAdd: false,
      subPageUrl: "",
      backSlash: true,
      backSlashParent: true,
      refresh: true,
      userId: EncryptUtility.localStorageDecrypt("userID"),
      entireLPNDisabled: false,
      showSelectLPNDisabled: false,
      ceValue: 0,
      showEntireLPNInTransit: false,
      disabledTakeOwnership: false,
      lpnNumber: "",
      showSelectLPNInTransit: false,
      viewLPNDisabled: true,
      targetInTransitLPN: "",
      partESNBCNSerialNo: "",
      sourceBinLPN: "",
      sourceLPN: "",
      lpnQuantity: 0,
      disableTargetLPN: false,
      disablePartBCNESN: true,
      disableBinLPN: true,
      disableLPN: true,
      disableQuantity: true,
      disableLPNTransfer: true,
      validLPNData: [],
      availableQty: 0,
      disableLPNNum: false,
      lpnDetailsDialog: false,
      lpnDetailsItems: [],
      resetDisabled: true,
      lpnDetailsHeaders: [
        { text: "Part Number", align: "start", value: "PartNumber" },
        { text: "Warehouse", value: "Warehouse" },
        { text: "Quantity", value: "Quantity" },
      ],
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
  },
  mounted() {
    this.onPageLoadGetButtons();
  },
  methods: {
    //Reset Function
    resetFunction() {
      this.entireLPNDisabled = false;
      this.showSelectLPNDisabled = false;
      this.disabledTakeOwnership = false;
      this.lpnNumber = "";
      this.showEntireLPNInTransit = false;
      this.showSelectLPNInTransit = false;
      this.viewLPNDisabled = true;
      this.targetInTransitLPN = "";
      this.partESNBCNSerialNo = "";
      this.sourceBinLPN = "";
      this.sourceLPN = "";
      this.lpnQuantity = 0;
      this.disableTargetLPN = false;
      this.disablePartBCNESN = true;
      this.disableBinLPN = true;
      this.disableLPN = true;
      this.disableQuantity = true;
      this.disableLPNTransfer = true;
      this.validLPNData = [];
      this.availableQty = 0;
      this.disableLPNNum = false;
      this.lpnDetailsDialog = false;
      this.lpnDetailsItems = [];
      this.resetDisabled = true;
      this.onPageLoadGetButtons();
      if (this.showEntireLPNInTransit) {
        this.$refs.EntireLPNForm.resetValidation();
      }
      if (this.showSelectLPNInTransit) {
        this.$refs.SelectLPNForm.resetValidation();
      }
    },
    //Get Buttons on page load
    async onPageLoadGetButtons() {
      let pageData = await materialMovementService.onPageLoadGetButtons("get", this.userId, this.ceValue);
      if (pageData.message !== "NA") {
        this.entireLPNDisabled = pageData[0].enable_entire == 1 ? false : true;
        this.showSelectLPNDisabled = pageData[0].enable_select == 1 ? false : true;
      }
    },
    //Take Ownership
    async takeOwnership() {
      if (this.$refs.EntireLPNForm.validate()) {
        let obj = {
          lpn: this.lpnNumber,
          user_id: parseInt(this.userId),
          method: 1,
          ce: parseInt(this.ceValue),
          web: 1,
        };
        let showMsg = true;
        let lpnTransferData = await materialMovementService.wholeLPNTransfer("post", obj, showMsg);
        if (lpnTransferData.message !== "NA") {
          this.entireLPNDisabled = lpnTransferData[0].enable_entire == 1 ? false : true;
          this.showSelectLPNDisabled = lpnTransferData[0].enable_select == 1 ? false : true;
          this.showEntireLPNInTransit = false;
          this.disableLPNNum = true;
          this.viewLPNDisabled = false;
          this.resetDisabled = false;
          this.onPageLoadGetButtons();
        }
      }
    },
    //Move select units button click event
    moveSelUnitsToIntransit() {
      this.showSelectLPNInTransit = true;
      this.showEntireLPNInTransit = false;
    },
    //Move entire unit click event
    moveEntireUnitToIntransit() {
      this.showEntireLPNInTransit = true;
      this.showSelectLPNInTransit = false;
    },
    //Get next available LPN number
    async getLPNNumber() {
      let lpnData = await commonAPIService.getNextAvailableLPN("get", this.userId);
      if (lpnData.message !== "NA") {
        this.targetInTransitLPN = lpnData[0].lpn;
        this.validateTargetLPN();
      }
    },
    //Validate Target LPN
    async validateTargetLPN() {
      if (this.targetInTransitLPN !== "" && this.targetInTransitLPN !== undefined) {
        this.resetDisabled = false;
        this.ceValue = 0;
        let obj = {
          lpn: this.targetInTransitLPN,
          user_id: parseInt(this.userId),
          method: 2,
          ce: this.ceValue,
        };
        let showMsg = true;
        let targetLPNValid = await materialMovementService.moveLPNtoIntransit("post", obj, showMsg);
        if (targetLPNValid.message !== "NA") {
          this.validLPNData = targetLPNValid;
          this.viewLPNDisabled = false;
          this.disableTargetLPN = true;
          this.disablePartBCNESN = false;
        }
      }
    },
    //Validation of Part/BCN/SerialNo/ESN
    async validatePartBCNESN() {
      if (this.partESNBCNSerialNo !== "" && this.partESNBCNSerialNo !== undefined) {
        this.ceValue = 0;
        let obj = {
          it_lpn: this.targetInTransitLPN,
          user_id: parseInt(this.userId),
          pass: 1,
          bcn: this.partESNBCNSerialNo,
          ce: this.ceValue,
        };
        let showMsg = true;
        let partBCNESNValid = await materialMovementService.validationCheckLPNBCN("post", obj, showMsg);
        if (partBCNESNValid.message !== "NA") {
          this.disablePartBCNESN = true;
          this.disableBinLPN = !partBCNESNValid[0].enable_bin;
          this.disableLPN = !partBCNESNValid[0].enable_lpn;
          this.disableQuantity = !partBCNESNValid[0].enable_qty;
          this.disableLPNTransfer = !partBCNESNValid[0].enable_submit;
        }
      }
    },
    //Validation of Bin/LPN
    async validateBinLPN() {
      if (this.sourceBinLPN !== "" && this.sourceBinLPN !== undefined) {
        this.ceValue = 0;
        let obj = {
          it_lpn: this.targetInTransitLPN,
          user_key: parseInt(this.userId),
          pass: 2,
          bcn: this.partESNBCNSerialNo,
          bin: this.sourceBinLPN,
          ce: this.ceValue,
        };
        let showMsg = true;
        let lpnBinValid = await materialMovementService.validationCheckLPNBCN("post", obj, showMsg);
        if (lpnBinValid.message !== "NA") {
          this.disableBinLPN = !lpnBinValid[0].enable_bin;
          this.disableLPN = !lpnBinValid[0].enable_lpn;
          this.disableQuantity = !lpnBinValid[0].enable_qty;
          this.disableLPNTransfer = !lpnBinValid[0].enable_submit;
        }
      }
    },
    //Validation of LPN
    async validateLPN() {
      if (this.sourceLPN !== "" && this.sourceLPN !== undefined) {
        this.ceValue = 0;
        let obj = {
          it_lpn: this.targetInTransitLPN,
          user_key: parseInt(this.userId),
          pass: 3,
          bcn: this.partESNBCNSerialNo,
          bin: this.sourceBinLPN,
          lpn: this.sourceLPN,
          ce: this.ceValue,
        };
        let showMsg = true;
        let lpnValid = await materialMovementService.validationCheckLPNBCN("post", obj, showMsg);
        if (lpnValid.message !== "NA") {
          this.disableBinLPN = !lpnValid[0].enable_bin;
          this.disableLPN = !lpnValid[0].enable_lpn;
          this.disableQuantity = !lpnValid[0].enable_qty;
          this.disableLPNTransfer = !lpnValid[0].enable_submit;
          this.availableQty = lpnValid[0].avail_qty;
        }
      }
    },
    //Validation of Quantity
    async validateQuantity() {
      if (this.lpnQuantity !== "" && this.lpnQuantity !== undefined) {
        this.ceValue = 0;
        let obj = {
          it_lpn: this.targetInTransitLPN,
          user_key: parseInt(this.userId),
          pass: 4,
          bcn: this.partESNBCNSerialNo,
          bin: this.sourceBinLPN,
          lpn: this.sourceLPN,
          qty: this.lpnQuantity,
          ce: this.ceValue,
        };
        let showMsg = true;
        let quantityValid = await materialMovementService.validationCheckLPNBCN("post", obj, showMsg);
        if (quantityValid.message !== "NA") {
          this.disableBinLPN = !quantityValid[0].enable_bin;
          this.disableLPN = !quantityValid[0].enable_lpn;
          this.disableQuantity = !quantityValid[0].enable_qty;
          this.disableLPNTransfer = !quantityValid[0].enable_submit;
        }
      }
    },
    //Transfer To LPN button click event
    async transferLPN() {
      if (this.$refs.SelectLPNForm.validate()) {
        let obj = {
          user_id: parseInt(this.userId),
          to_lpn: this.targetInTransitLPN,
          bcn: this.partESNBCNSerialNo,
          from_bin: this.sourceBinLPN,
          from_lpn: this.sourceLPN,
          qty: this.lpnQuantity,
          ce: this.ceValue,
        };
        let showMsg = true;
        let lpnTransfered = await materialMovementService.transferLPNIntransit("post", obj, showMsg);
        if (lpnTransfered.message !== "NA") {
          this.showSelectLPNInTransit = false;
          this.viewLPNDisabled = true;
          this.onPageLoadGetButtons();
        }
      }
    },
    //Get LPN content
    async getLPNContents() {
      this.lpnDetailsItems = [];
      let obj = {
        lpn: this.targetInTransitLPN,
        user_key: parseInt(this.userId),
        transferred: 1,
      };
      let showMessage = false;
      let lpnContents = await materialMovementService.getLPNContents("post", obj, showMessage);
      if (lpnContents.message !== "NA") {
        this.lpnDetailsItems = lpnContents.Items;
        this.viewLPNDisabled = false;
        this.lpnDetailsDialog = true;
      }
    },
    //Close Dialog
    closeDialog() {
      if (this.lpnDetailsDialog) {
        this.lpnDetailsDialog = false;
        this.lpnDetailsItems = [];
        this.viewLPNDisabled = false;
      }
    },
    //Reset forms button
    formsReset() {
      if (this.showEntireLPNInTransit) {
        this.lpnNumber = "";
        this.resetDisabled = true;
        this.viewLPNDisabled = true;
        this.$refs.EntireLPNForm.resetValidation();
      }
      if (this.showSelectLPNInTransit) {
        this.targetInTransitLPN = "";
        this.partESNBCNSerialNo = "";
        this.sourceBinLPN = "";
        this.sourceLPN = "";
        this.lpnQuantity = 0;
        this.availableQty = 0;
        this.disableTargetLPN = false;
        this.disablePartBCNESN = true;
        this.disableBinLPN = true;
        this.disableLPN = true;
        this.disableQuantity = true;
        this.disableLPNTransfer = true;
        this.resetDisabled = true;
        this.viewLPNDisabled = true;
        this.$refs.SelectLPNForm.resetValidation();
      }
    },
    //To brings page based actions to the page
    actionSetter(action) {
      if (this.actionList !== undefined && this.actionList !== null && this.actionList !== "") {
        let filter = this.actionList.filter((entry) => entry.SystemName === action);
        return filter !== undefined && filter !== null && filter.length == 1 ? true : false;
      } else return false;
    },
    //Add or remove Favourite
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
  },
  components: {
    breadcrumbComp,
  },
};
